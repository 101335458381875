
import React, { useState, useEffect, memo, useMemo, useRef } from 'react';
import ValuesStore from '../../store/values-store';
import { useSearchParams, Link, useLocation, useNavigate } from 'react-router-dom';
import utils from '../../dependencies/custom/react-utilities';
import { Space, Card, Button, Tag, message, DatePicker } from 'antd';
import useTable from '../../hooks/table';
import useEdit from '../../hooks/edit';
import useAdd from '../../hooks/add';
import useDelete from '../../hooks/delete';
import Settings from '../../dependencies/custom/settings';


const Delivery = (props) => {
    const valuesStore = ValuesStore();
    const edit = useAdd('tables_metadata', 'table_name');//make this hook be aware of where to get tbl metadata 
    const add = useAdd('tables_metadata', 'table_name');
    const del = useDelete();
    const { filters, filterTypes } = utils.generateTableFilters();
    const navigate = useNavigate();
    //and key value that points to the table names from zustand store.    
    const table = useTable(
        {
            pagination: {
                current: 1,
                pageSize: 10,
            },
            filters: { ...filters },
            filterTypes: { ...filterTypes }
        },
        `${Settings.backend}/get_delivery`,
        'post',
        'result',
        'totalCount',
        'id',
        { prop: 'LIKE', value: 'LIKE' },
        { table: 'production2delivery_txn', fields: ['*'] });

    const columns = ([
        {
            title: 'Reversal',
            dataIndex: 'is_reversal',
            filters: [
                {
                    value: 1,
                    text: 'Yes'
                },
                {
                    value: 0,
                    text: 'No'
                }
            ],
            render: (v, record) => {
                return <Tag color={v ? 'blue-inverse' : 'green-inverse'}>{v ? 'Yes' : 'No'}</Tag>
            }

        },
        {
            title: 'ID',
            dataIndex: 'custom_id',
            ...table.getColumnSearchProps('custom_id'),
        },
        {
            title: 'Txn. Item',
            dataIndex: 'pd_item',
        },
        {
            title: 'Product',
            dataIndex: 'product_item',
            filterSearch: true,
        },
        {
            title: 'ReceiptNo.',
            dataIndex: 'sales_receipt_no',
            ...table.getColumnSearchProps('sales_receipt_no'),
        },
        {
            title: 'Qty',
            dataIndex: 'qty',
            render: (value, record) => {
                return Math.round(value);
             },
        },
        {
            title: 'Cum. Qty.',
            dataIndex: 'cum_qty',
            render: (value, record) => {
                return Math.round(value);
             },
        },
        {
            title: 'Blk Site',
            dataIndex: 'product_cum',
            render: (value, record) => {
                return Math.round(value);
             },
        },
        {
            title: 'Date',
            dataIndex: 'date_inserted',
            ...table.getColumnSearchProps('date_inserted'),
            render: (v, record) => {
                return utils.formatDate(v);
            }
        },
        {
            title: 'Actions',
            key: 'action',
            render: (_, record) => {
                return <Space size="middle">
                    {/* <Button className='btn-successx border-0x' onClick={e => editRecord(record, 'DELIVERY')}><i className='fas fa-eye text-primary' /></Button> */}
                    <Button className='btn-successx border-0x' onClick={e => editRecord(record, 'DELIVERY')}><i className='fas fa-undo text-success' /></Button>
                </Space>
            },
        },
    ]);


    async function editRecord(record, tableName) {
        const receipt_no = record.sales_receipt_no;       
        let res = await utils.requestWithReauth('post', `${Settings.backend}/get_waybills`, null, { receipt_no });
        edit.setTblName(tableName);
        edit.setShowModal(true);
        edit.setSaveCompleted(false);

        let driver = '';
        let dispatcher = '';
        let truck_no = '';
        if (res.status == 'Ok') {
            const r = res.results[0];
            driver = r.driver;
            dispatcher = r.dispatcher;
            truck_no = r.truck_no;
        }

        edit.setRecord({ ...record,  delivery_id: record.custom_id, driver, dispatcher, truck_no });
        //     const storeKey = 'editableRecord';
        //     valuesStore.setValue(storeKey, record);
        //     edit.setTblName(tableName);
        //     edit.setData(record);
        //     edit.setRecordKey(storeKey);
        //     edit.setShowModal(true);
        // edit.setSaveCompleted(false);
        console.log(record.custom_id);
    }

    function addRecord(tableName = 'DELIVERY') {
        add.setTblName(tableName);
        add.setShowModal(true);
        add.setSaveCompleted(false);
    }


    async function editOnOk() {
        edit.setLoading(true);
        let res = await utils.requestWithReauth('post', `${Settings.backend}/edit_production2delivery_txn`, null, { record: edit.record, type: 'delivery' });
        if (res.status == 'Ok') {
            edit.reset();
            message.success('Operation successful');
        } else {
            message.error(res.msg);
        }
        edit.setLoading(false);
    }

    async function addOnOk() {
        let res = await utils.requestWithReauth('post', `${Settings.backend}/add_delivery`, null, add.record);
        if (res.status == 'Ok') {
            add.reset();
            message.success('Operation successful');
        } else {
            message.error(res.msg);
        }
    }

    const colFilters = [
        {
            filter: 'product_item',
            sql: 'SELECT custom_id,product_item FROM products WHERE for_inventory = 0',
            key: 'custom_id',
            value: 'product_item'
        }
    ];


    useMemo(() => {
        table.setColumns(columns);
        table.setColFilters(colFilters, columns, `${Settings.backend}/get_col_filters`);
        console.log('looping')
        table.fetchData();
    }, [add.saveCompleted, edit.saveCompleted, del.saveCompleted, table.extraFetchParams]);


    return (
        <>
            {/* {console.log('render render')} */}
            <div className='containerx' /*style={{ marginTop: '4rem' }}*/>
                <div className='row'>
                    <div className='col-md-12'>
                        <Card
                            bordered={false}
                            className="criclebox tablespace border-0 mb-24"
                            title="Delivery"
                            extra={
                                <Space>
                                    <DatePicker.RangePicker onChange={v => utils.filterByDate('date_inserted', v, table)} />
                                    <Button className='text-white amber accent-4 border-0' onClick={e => addRecord()}><i className='fas fa-plus me-2' /> New Delivery</Button>
                                </Space>
                            }
                        >
                            <div className="table-responsive">
                                {table.table}
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            {edit.addModal('Edit Item', editOnOk)}
            {add.addModal('Add New Item', addOnOk)}
        </>
    );
}

export default memo(Delivery);